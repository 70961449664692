import React, { createContext, useContext, useState } from "react"

import ConfirmDialog from "../components/MenusAndModals/ConfirmDialog"

type ConfirmOptions = {
  message?: string
}

type ConfirmContextType = {
  confirm: (options?: ConfirmOptions) => Promise<boolean>
}

const ConfirmContext = createContext<ConfirmContextType>({
  confirm: async () => false,
})

type ConfirmState = {
  isOpen: boolean
  resolve: ((value: boolean) => void) | null
  message?: string
}

export const ConfirmProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<ConfirmState>({
    isOpen: false,
    resolve: null,
    message: undefined,
  })

  const confirm = async (options: ConfirmOptions = {}) => {
    return new Promise<boolean>((resolve) => {
      setState({
        isOpen: true,
        resolve,
        message: options.message,
      })
    })
  }

  const handleClose = () => {
    state.resolve?.(false)
    setState((prev) => ({ ...prev, isOpen: false, resolve: null }))
  }

  const handleConfirm = () => {
    state.resolve?.(true)
    setState((prev) => ({ ...prev, isOpen: false, resolve: null }))
  }

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      {state.isOpen && <ConfirmDialog onClose={handleClose} onConfirm={handleConfirm} message={state.message} />}
    </ConfirmContext.Provider>
  )
}

export const useConfirmContext = () => useContext(ConfirmContext)
