import { MouseEventHandler } from "react"

import { Box, Button, ButtonProps } from "@chakra-ui/react"

import Tooltip from "../Indicators/Tooltip"

interface PrimaryButtonProps extends ButtonProps {
  onClickHandler?: MouseEventHandler
  isLoading?: boolean
  tooltip?: string
  children: React.ReactNode
}

const PrimaryButton = ({ onClickHandler = (e) => {}, isLoading = false, tooltip = "", children, ...props }: PrimaryButtonProps) => {
  const button = (
    <Button
      backgroundColor="var(--ui-color-background-button-primary)"
      color="white"
      boxShadow="none"
      _hover={{
        boxShadow: "md",
      }}
      _active={{
        backgroundColor: "var(--ui-color-background-button-primary-active)",
      }}
      _disabled={{
        backgroundColor: "var(--ui-color-background-button-primary-disabled)",
        color: "var(--ui-color-text-button-primary-disabled)",
        cursor: "not-allowed",
        boxShadow: "none",
      }}
      isLoading={isLoading}
      onClick={onClickHandler}
      {...props}
    >
      {children}
    </Button>
  )

  return tooltip ? (
    <Tooltip label={tooltip}>
      <Box>{button}</Box>
    </Tooltip>
  ) : (
    <>{button}</>
  )
}

export default PrimaryButton
