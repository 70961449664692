import { useRef } from "react"

import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from "@chakra-ui/react"

import PrimaryButton from "../Buttons/Primary"
import SecondaryButton from "../Buttons/Secondary"

const ConfirmDialog = ({ onClose, onConfirm, message = "Are you sure? You cannot undo this action afterwards." }: { onClose: () => void; onConfirm: () => void; message?: string }) => {
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog isOpen={true} leastDestructiveRef={cancelRef} onClose={onClose} isCentered motionPreset="scale">
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Confirm
          </AlertDialogHeader>

          <AlertDialogBody>{message}</AlertDialogBody>

          <AlertDialogFooter justifyContent="flex-end">
            <SecondaryButton ref={cancelRef} onClick={onClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={onConfirm} ml={3}>
              Confirm
            </PrimaryButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default ConfirmDialog
