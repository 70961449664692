import { MouseEventHandler } from "react"
import React from "react"

import { Box, Button, ButtonProps } from "@chakra-ui/react"

import Tooltip from "../Indicators/Tooltip"

interface SecondaryButtonProps extends ButtonProps {
  onClickHandler?: MouseEventHandler
  isLoading?: boolean
  tooltip?: string
}

const SecondaryButton = React.forwardRef<HTMLButtonElement, SecondaryButtonProps>(({ onClickHandler = (e) => {}, isLoading = false, tooltip = "", children, ...props }, ref) => {
  const button = (
    <Button
      ref={ref}
      backgroundColor="white"
      border="1px solid #E2E8F0"
      boxShadow="none"
      _hover={{
        boxShadow: "md",
      }}
      _active={{
        backgroundColor: "#EDF2F6",
      }}
      _disabled={{
        borderColor: "var(--ui-color-border-button-secondary-disabled)",
        color: "var(--ui-color-text-button-secondary-disabled)",
        cursor: "not-allowed",
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
      isLoading={isLoading}
      onClick={onClickHandler}
      {...props}
    >
      {children}
    </Button>
  )

  return tooltip ? (
    <Tooltip label={tooltip}>
      <Box display="inline-block">{button}</Box>
    </Tooltip>
  ) : (
    <>{button}</>
  )
})

export default SecondaryButton
