import { Tooltip as ChakraTooltip } from "@chakra-ui/react"

const Tooltip = ({ label, children }) => {
  return (
    <ChakraTooltip label={label} placement="top" backgroundColor="#171923" padding="12px" borderRadius="4px" color="white" lineHeight="20px" marginBottom="12px" fontSize="14px" hasArrow>
      {children}
    </ChakraTooltip>
  )
}

export default Tooltip
